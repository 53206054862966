<template>
  <navigation index="sysStoreManage" ref="navigation">
    <div :style="{ height: ContentHeight - 100 + 'px' }">
      <div
        class="topnavigation"
        style="gap: 10px; justify-content: space-between"
      >
        <div style="display: flex; gap: 10px"></div>
      </div>

      <el-tabs v-model="activeName">
        <el-tab-pane label="默认条款" name="默认条款">
          <div
            style="width: calc(100% - 40px); overflow: auto"
            :style="{ height: ContentHeight + 'px' }"
          >
            <legend>{{ tools.GetLanguageValue("web.条款") }}</legend>
            {{ tools.GetLanguageValue("web.预定条款") }} :
            <QuillEditor
              v-model:content="StoreSettingsOpt.ReserveTerm"
              :content-type="'html'"
              :toolbar="editToolbar"
              theme="snow"
              style="height: 150px"
            ></QuillEditor>

            <br />
            {{ tools.GetLanguageValue("web.维修条款") }}:
            <QuillEditor
              v-model:content="StoreSettingsOpt.ConditionsOfRepair"
              :content-type="'html'"
              :toolbar="editToolbar"
              style="height: 150px"
              theme="snow"
            ></QuillEditor>

            <br />
            {{ tools.GetLanguageValue("web.保修条款") }}:
            <QuillEditor
              v-model:content="StoreSettingsOpt.WarrantyTerms"
              :content-type="'html'"
              :toolbar="editToolbar"
              style="height: 150px"
              theme="snow"
            ></QuillEditor>

            <br />
            {{ tools.GetLanguageValue("web.备注") }}:
            <QuillEditor
              v-model:content="StoreSettingsOpt.Remark"
              :content-type="'html'"
              :toolbar="editToolbar"
              style="height: 150px"
              theme="snow"
            ></QuillEditor>
            <br />

            {{ tools.GetLanguageValue("web.销售小票备注") }}:

            <el-input
              v-model="StoreSettingsOpt.MarketReceiptRemark"
              type="textarea"
            />
            <br />

            {{ tools.GetLanguageValue("web.销售A4小票备注") }}:

            <el-input
              v-model="StoreSettingsOpt.MarketA4Remark"
              type="textarea"
            />
            <br />
          </div>
          <el-button @click="InsertStoreSettings">保存</el-button>
        </el-tab-pane>

        <el-tab-pane
          :label="item.LanguageName + '-条款'"
          :name="item.LanguageName + '-条款'"
          v-for="item in StoreSettingLanguages"
          :key="item"
        >
          <div
            style="width: calc(100% - 40px); overflow: auto"
            :style="{ height: ContentHeight + 'px' }"
          >
            <legend>{{ tools.GetLanguageValue("web.条款") }}</legend>
            {{ tools.GetLanguageValue("web.预定条款") }} :
            <QuillEditor
              v-model:content="item.Language.ReserveTerm"
              :content-type="'html'"
              :toolbar="editToolbar"
              theme="snow"
              style="height: 150px"
            ></QuillEditor>

            <br />
            {{ tools.GetLanguageValue("web.维修条款") }}:
            <QuillEditor
              v-model:content="item.Language.ConditionsOfRepair"
              :content-type="'html'"
              :toolbar="editToolbar"
              style="height: 150px"
              theme="snow"
            ></QuillEditor>

            <br />
            {{ tools.GetLanguageValue("web.保修条款") }}:
            <QuillEditor
              v-model:content="item.Language.WarrantyTerms"
              :content-type="'html'"
              :toolbar="editToolbar"
              style="height: 150px"
              theme="snow"
            ></QuillEditor>

            <br />
            {{ tools.GetLanguageValue("web.备注") }}:
            <QuillEditor
              v-model:content="item.Language.Remark"
              :content-type="'html'"
              :toolbar="editToolbar"
              style="height: 150px"
              theme="snow"
            ></QuillEditor>
            <br />

            {{ tools.GetLanguageValue("web.销售小票备注") }}:

            <el-input
              v-model="item.Language.MarketReceiptRemark"
              type="textarea"
            />
            <br />

            {{ tools.GetLanguageValue("web.销售A4小票备注") }}:

            <el-input v-model="item.Language.MarketA4Remark" type="textarea" />
            <br />
          </div>
          <el-button @click="InsertLanguageStoreSettings"
            >保存多语言条款</el-button
          >
        </el-tab-pane>

        <el-tab-pane label="默认属性" name="默认属性">
          <el-table
            border
            :data="AttributeGroup"
            :height="ContentHeight + 'px'"
          >
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column
              :label="tools.GetLanguageValue('web.属性名称')"
              width="180"
              prop="Name"
            />
            <el-table-column
              :label="tools.GetLanguageValue('web.排序')"
              width="180"
              prop="Sort"
            />
            <el-table-column
              :label="tools.GetLanguageValue('web.备注')"
              width="180"
              prop="Desc"
            />
            <el-table-column
              :label="tools.GetLanguageValue('web.操作')"
              fixed="right"
              width="350"
            >
              <template #default="scope">
                <el-button
                  type="success"
                  size="small"
                  @click="GetAttributesByGroupId(scope.row)"
                >
                  {{ tools.GetLanguageValue("web.编辑内容") }}</el-button
                >
                <el-button
                  size="small"
                  @click="DeleteAttributeGroupById(scope.row)"
                  type="danger"
                >
                  {{ tools.GetLanguageValue("web.删除") }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <el-button
            style="margin: 5px"
            @click="OpenDefaultProductAttributeGroup({ Sort: 0 })"
            >新增</el-button
          >
        </el-tab-pane>
        <el-tab-pane label="默认品牌型号" name="默认品牌型号">
          <el-row>
            <el-col :span="6">
              <el-button
                style="margin: 5px"
                @click="OpenProductBrandWindow({ Sort: 0 })"
                >新增</el-button
              >
              <el-table
                @cell-click="CheckProductBrand"
                :data="ProductBrandDatas"
                style="width: 100%; height: 100%"
                :height="ContentHeight"
              >
                <el-table-column
                  :label="tools.GetLanguageValue('web.名称')"
                  prop="Name"
                  width="180"
                />

                <el-table-column
                  width="140"
                  :label="tools.GetLanguageValue('web.操作')"
                >
                  <template #default="scope">
                    <el-button
                      size="small"
                      @click="OpenProductBrandWindow(scope.row)"
                    >
                      {{ tools.GetLanguageValue("web.编辑") }}</el-button
                    >
                    <el-button
                      @click="DeleteProductBrand(scope.row)"
                      type="danger"
                      size="small"
                    >
                      {{ tools.GetLanguageValue("web.删除") }}</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
            <el-col :span="18">
              <el-button
                style="margin: 5px"
                @click="OpenProductModelWindow({ Sort: 0 })"
                >新增</el-button
              >
              <el-button style="margin: 5px" @click="DeleteProductModes">
                删除</el-button
              >
              <el-button style="margin: 5px" @click="ForbiddenProductModels()">
                禁用</el-button
              >
              <el-button style="margin: 5px" @click="CancelForbiddenProductModels()">
                取消禁用</el-button
              >

              
              <el-table
                border
                :data="ProductModelDatas"
                style="width: 100%; height: calc(100% - 30px)"
                :height="ContentHeight"
                @selection-change="handleSelectionChange"
              >
                <el-table-column type="index" width="50"> </el-table-column>
                <el-table-column type="selection" width="55" />
                <el-table-column
                  :label="tools.GetLanguageValue('web.名称')"
                  width="180"
                  prop="Name"
                />
                <el-table-column
                  label="是否禁用"
                  width="100"
                  prop="IsForbidden"
                />

                <el-table-column
                  :label="tools.GetLanguageValue('web.编码')"
                  width="180"
                  prop="Code"
                />

                <el-table-column
                  :label="tools.GetLanguageValue('web.排序')"
                  width="80"
                  prop="Sort"
                />

                <el-table-column
                  :label="tools.GetLanguageValue('web.备注')"
                  width="180"
                  prop="Description"
                />

                <el-table-column :label="tools.GetLanguageValue('web.操作')">
                  <template #default="scope">
                    <el-button
                      type="success"
                      size="small"
                      @click="OpenProductModelWindow(scope.row)"
                    >
                      {{ tools.GetLanguageValue("web.编辑") }}</el-button
                    >
                    <el-button
                      size="small"
                      @click="DeleteProductModel(scope.row)"
                      type="danger"
                    >
                      {{ tools.GetLanguageValue("web.删除") }}</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- 添加 -->
    <el-dialog
      v-model="ProductBrandWindow"
      :title="tools.GetLanguageValue('web.编辑')"
      width="800px"
      :show-close="false"
    >
      <el-form
        ref="form"
        :model="ProductBrandOpt"
        label-width="80px"
        style="margin-top: 10px"
      >
        <el-form-item :label="tools.GetLanguageValue('web.名称')">
          <el-input
            v-model="ProductBrandOpt.Name"
            @focus="InputSelect"
            :placeholder="tools.GetLanguageValue('web.请输入品牌名称')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="tools.GetLanguageValue('web.编号')">
          <el-input
            v-model="ProductBrandOpt.Code"
            @focus="InputSelect"
            :placeholder="tools.GetLanguageValue('web.请输入编号')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="tools.GetLanguageValue('web.排序')">
          <el-input
            v-model="ProductBrandOpt.Sort"
            @focus="InputSelect"
            :placeholder="tools.GetLanguageValue('web.请输入排序')"
          ></el-input>
        </el-form-item>

        <el-form-item :label="tools.GetLanguageValue('web.备注')">
          <el-input
            v-model="ProductBrandOpt.Description"
            @focus="InputSelect"
            :placeholder="tools.GetLanguageValue('web.请输入备注')"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="ProductBrandWindow = false">
            {{ tools.GetLanguageValue("web.关闭") }}</el-button
          >
          <el-button @click="InsertProductBrand" type="primary">
            {{ tools.GetLanguageValue("web.保存") }}</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 新增属性组 -->
    <el-dialog
      v-model="AttributeGroupWindow"
      :title="tools.GetLanguageValue('web.新增属性')"
      width="800px"
      :draggable="true"
      :show-close="false"
    >
      <el-tabs v-model="LanguageName">
        <el-form
          ref="form"
          label-width="120px"
          v-model="AttributeGroupModel"
          :model="AttributeGroupModel"
          style="margin-top: 10px"
        >
          <el-form-item
            :label="tools.GetLanguageValue('web.属性名称')"
            prop="Name"
          >
            <el-input
              v-model="AttributeGroupModel.Name"
              @focus="InputSelect($event)"
              :placeholder="tools.GetLanguageValue('web.请输入属性名称')"
            ></el-input>
          </el-form-item>

          <el-form-item :label="tools.GetLanguageValue('web.排序')" prop="Name">
            <el-input-number
              v-model="AttributeGroupModel.Sort"
              @focus="InputSelect($event)"
            ></el-input-number>
          </el-form-item>
          <el-form-item :label="tools.GetLanguageValue('web.备注')" prop="Name">
            <el-input
              @focus="InputSelect($event)"
              v-model="AttributeGroupModel.Desc"
              :placeholder="tools.GetLanguageValue('web.请输入备注')"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-tabs>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="AttributeGroupWindow = !AttributeGroupWindow">
            {{ tools.GetLanguageValue("web.关闭") }}</el-button
          >
          <el-button @click="InsterProductAttributeGroup" type="primary">{{
            tools.GetLanguageValue("web.确定")
          }}</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 添加属性小项 -->
    <el-dialog
      v-model="AttributeItemWindow"
      :title="tools.GetLanguageValue('web.属性')"
      width="70%"
      :show-close="false"
    >
      <el-row style="margin: 5px">
        <el-col :span="15">
          <el-table border :data="AttributeGroupItemList" height="500px">
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column
              :label="tools.GetLanguageValue('web.属性名称')"
              width="180"
              prop="Name"
            />
            <el-table-column
              :label="tools.GetLanguageValue('web.排序')"
              width="80"
              prop="Sort"
            />
            <el-table-column
              :label="tools.GetLanguageValue('web.备注')"
              width="180"
              prop="Desc"
            />
            <el-table-column
              :label="tools.GetLanguageValue('web.操作')"
              fixed="right"
              width="200"
            >
              <template #default="scope">
                <el-button
                  type="success"
                  size="small"
                  @click="OpenttributeItemWindow(scope.row)"
                >
                  {{ tools.GetLanguageValue("web.编辑") }}</el-button
                >

                <el-button
                  size="small"
                  @click="DeleteProductAttribute(scope.row)"
                  type="danger"
                >
                  {{ tools.GetLanguageValue("web.删除") }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="8">
          <div style="width: 100%; height: 300px; padding: 15px">
            <el-form
              ref="form"
              label-width="120px"
              style="margin: 15px"
              v-model="AttributeGroupItemModel"
              :model="AttributeGroupItemModel"
              :rules="AttributeGroupRules"
            >
              <el-form-item
                :label="tools.GetLanguageValue('web.项名称')"
                prop="Name"
              >
                <el-input
                  @focus="InputSelect($event)"
                  v-model="AttributeGroupItemModel.Name"
                  :placeholder="tools.GetLanguageValue('web.项名称')"
                ></el-input>
              </el-form-item>
              <el-form-item
                :label="tools.GetLanguageValue('web.排序')"
                prop="Sort"
              >
                <el-input-number
                  v-model="AttributeGroupItemModel.Sort"
                  :placeholder="tools.GetLanguageValue('web.请输入排序')"
                  @focus="InputSelect($event)"
                ></el-input-number>
              </el-form-item>
              <el-form-item
                :label="tools.GetLanguageValue('web.备注')"
                prop="Name"
              >
                <el-input
                  v-model="AttributeGroupItemModel.Desc"
                  :placeholder="tools.GetLanguageValue('web.请输入备注')"
                  @focus="InputSelect($event)"
                ></el-input>
              </el-form-item>
            </el-form>
            <div style="width: 100%; text-align: center">
              <el-button
                type="primary"
                style="width: 150px; height: 40px"
                @click="InsertAttributeGroupItem"
              >
                {{ tools.GetLanguageValue("web.保存") }}</el-button
              >
            </div>
          </div>
        </el-col>
      </el-row>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="AttributeItemWindow = !AttributeItemWindow">
            {{ tools.GetLanguageValue("web.关闭") }}</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 添加型号 -->
    <el-dialog
      v-model="ProductModelWindow"
      :title="tools.GetLanguageValue('web.编辑')"
      width="800px"
      :show-close="false"
    >
      <el-form ref="form" label-width="80px" style="margin-top: 10px">
        <el-form-item :label="tools.GetLanguageValue('web.名称')">
          <el-input
            v-model="ProductModelOpt.Name"
            @focus="InputSelect"
            :placeholder="tools.GetLanguageValue('web.请输入型号名称')"
          ></el-input>
        </el-form-item>

        <el-form-item :label="tools.GetLanguageValue('web.品牌')">
          <el-input
            v-model="this.ProductBrandOpt.Name"
            disabled="true"
          ></el-input>
        </el-form-item>

        <el-form-item :label="tools.GetLanguageValue('web.编号')">
          <el-input
            v-model="ProductModelOpt.Code"
            @focus="InputSelect"
            :placeholder="tools.GetLanguageValue('web.请输入编号')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="tools.GetLanguageValue('web.排序')">
          <el-input-number
            v-model="ProductModelOpt.Sort"
            @focus="InputSelect"
            :placeholder="tools.GetLanguageValue('web.请输入排序')"
          ></el-input-number>
        </el-form-item>

        <el-form-item :label="tools.GetLanguageValue('web.备注')">
          <el-input
            v-model="ProductModelOpt.Description"
            @focus="InputSelect"
            :placeholder="tools.GetLanguageValue('web.请输入备注')"
          ></el-input>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="ProductModelWindow = false">
            {{ tools.GetLanguageValue("web.关闭") }}</el-button
          >
          <el-button @click="InsertProductModel()" type="primary">
            {{ tools.GetLanguageValue("web.保存") }}</el-button
          >
        </span>
      </template>
    </el-dialog>
  </navigation>
</template> 
  <script >
import axios from "../../../commons/AxiosMethod.js";
import tools from "../../../commons/tools.js";
import { ElMessageBox, ElMessage } from "element-plus";
import "../../../assets/css/commons.css";
import { Search, Close, Refresh, Message } from "@element-plus/icons-vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import navigation from "../../../components/Modules/AdminNavigation.vue";
export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      AuthorizationWindowActiveName: "first",
      tools: tools,
      ContentHeight: document.documentElement.clientHeight - 200,

      TableHeight: document.documentElement.clientHeight - 120,
      AttributeGroup: [],
      activeName: "默认条款",
      clientHeight: document.documentElement.clientHeight,
      PaddingAuthorizationLog: {
        Page: 1,
        Size: 30,
        Count: 0,
      },

      AttributeGroupWindow: false,
      AttributeGroupModel: {},
      AttributeGroupItemModel: {},
      AttributeItemWindow: false,
      AttributeGroupItemList: [],

      ProductBrandDatas: [], //品牌
      ProductBrandOpt: {},
      ProductBrandWindow: false,

      ProductModelWindow: false,

      ProductModelOpt: {},
      ProductModelDatas: [],
      StoreSettingsOpt: {},

      StoreSettingLanguages: [],
      MultipleSelection: [],
      editToolbar: [["bold", "italic", "underline", "strike"]],
    };
  },
  name: "App",
  mounted() {
    this.ApiUrl = axios.GetUrl();
    this.UploadHeaders = {
      Authorization: localStorage.getItem("Authentication_Header"),
    };

    this.TableHeight = document.documentElement.clientHeight - 120;
    this.ContentHeight = document.documentElement.clientHeight - 200;

    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.TableHeight = document.documentElement.clientHeight - 120;
        this.ContentHeight = document.documentElement.clientHeight - 200;
        this.$refs.navigation.onresize();
      })();
    };

    this.GetProductAttributeGroup();
    this.GetProductBrands();
    this.GetStoreSettings();
    this.GetStoreSettingsByLanguageId();
  },
  components: { navigation, QuillEditor },
  methods: {
    handleSelectionChange(vals) {
      this.MultipleSelection = vals;
    },

    CancelForbiddenProductModels() {
      ElMessageBox.confirm(
        "您确定要恢复这" + this.MultipleSelection.length + "个型号吗？",
        "Warning",
        {
          confirmButtonText: tools.GetLanguageValue("web.确定"),
          cancelButtonText: tools.GetLanguageValue("web.取消"),
          type: "warning",
          title: tools.GetLanguageValue("web.提示"),
        }
      )
        .then(() => {
          axios.apiMethod(
            "/defaultData/DefaultData/CancelForbiddenProductModels",
            "post",
            this.MultipleSelection,
            (result) => {
              this.GetProductModelList();
            }
          );
        })
        .catch(() => {});
    },
    ForbiddenProductModels() {
      ElMessageBox.confirm(
        "您确定要禁用这" + this.MultipleSelection.length + "个型号吗？",
        "Warning",
        {
          confirmButtonText: tools.GetLanguageValue("web.确定"),
          cancelButtonText: tools.GetLanguageValue("web.取消"),
          type: "warning",
          title: tools.GetLanguageValue("web.提示"),
        }
      )
        .then(() => {
          axios.apiMethod(
            "/defaultData/DefaultData/ForbiddenProductModels",
            "post",
            this.MultipleSelection,
            (result) => {
              this.GetProductModelList();
            }
          );
        })
        .catch(() => {});
    },
    //批量删除
    DeleteProductModes() {
      ElMessageBox.confirm(
        "您确定要删除这" + this.MultipleSelection.length + "个型号吗？",
        "Warning",
        {
          confirmButtonText: tools.GetLanguageValue("web.退出"),
          cancelButtonText: tools.GetLanguageValue("web.取消"),
          type: "warning",
          title: tools.GetLanguageValue("web.提示"),
        }
      )
        .then(() => {
          axios.apiMethod(
            "/defaultData/DefaultData/DeleteProductModels",
            "post",
            this.MultipleSelection,
            (result) => {
              this.GetProductModelList();
            }
          );
        })
        .catch(() => {});
    },
    //插入多语言的配置信息
    InsertLanguageStoreSettings() {
      axios.apiMethod(
        "/defaultData/DefaultData/InsertLanguageStoreSettings",
        "post",
        this.StoreSettingLanguages,
        (result) => {
          this.GetStoreSettingsByLanguageId();
        }
      );
    },
    //获取其他语言的配置
    GetStoreSettingsByLanguageId() {
      axios.apiMethod(
        "/defaultData/DefaultData/GetStoreSettingsByLanguageId",
        "get",
        {},
        (result) => {
          this.StoreSettingLanguages = result.Data;
        }
      );
    },
    InsertStoreSettings() {
      axios.apiMethod(
        "/defaultData/DefaultData/InsertStoreSettings",
        "post",
        this.StoreSettingsOpt,
        (result) => {
          this.GetStoreSettings();
        }
      );
    },

    GetStoreSettings() {
      axios.apiMethod(
        "/defaultData/DefaultData/GetStoreSettings",
        "get",
        {},
        (result) => {
          this.StoreSettingsOpt = result.Data;
        }
      );
    },

    //删除型号
    DeleteProductModel(item) {
      axios.apiMethod(
        "/defaultData/DefaultData/DeleteProductModel",
        "get",
        { modelId: item.Id },
        (result) => {
          this.GetProductModelList();
        }
      );
    },
    //编辑属性详细
    OpenttributeItemWindow(item) {
      this.AttributeGroupItemModel = item;
    },

    //选择品牌
    CheckProductBrand(row, column, cell, event) {
      this.ProductBrandOpt = row;
      this.GetProductModelList();
    },
    //插入型号
    InsertProductModel() {
      this.ProductModelOpt.BrandId = this.ProductBrandOpt.Id;
      axios.apiMethod(
        "/defaultData/DefaultData/InsertProductModel",
        "post",
        this.ProductModelOpt,
        (result) => {
          this.ProductModelWindow = false;
          this.GetProductModelList();
        }
      );
    },
    //打开编辑窗口
    OpenProductModelWindow(item) {
      this.ProductModelOpt = item;
      this.ProductModelWindow = true;
    },
    //获取型号
    GetProductModelList() {
      axios.apiMethod(
        "/defaultData/DefaultData/GetProductModelList",
        "get",
        {
          brandId: this.ProductBrandOpt.Id,
        },
        (result) => {
          this.ProductModelDatas = result.Data;
        }
      );
    },

    //删除品牌
    DeleteProductBrand(item) {
      axios.apiMethod(
        "/defaultData/DefaultData/DeleteProductBrands",
        "get",
        {
          brandId: item.Id,
        },
        (result) => {
          if (result.Data.IsSuccess) {
            this.ProductBrandWindow = false;
            this.GetProductBrands();
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error",
            });
          }
        }
      );
    },
    //插入品牌
    InsertProductBrand() {
      axios.apiMethod(
        "/defaultData/DefaultData/InsertProductBrands",
        "post",
        this.ProductBrandOpt,
        (result) => {
          if (result.Data.IsSuccess) {
            this.ProductBrandWindow = false;
            this.GetProductBrands();
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error",
            });
          }
        }
      );
    },
    //获取品牌
    GetProductBrands() {
      axios.apiMethod(
        "/defaultData/DefaultData/GetProductBrands",
        "get",
        {},
        (result) => {
          this.ProductBrandDatas = result.Data;
          if (result.Data.length > 0) {
            this.ProductBrandOpt = result.Data[0];
            this.GetProductModelList();
          } else {
            this.ProductBrandOpt = {};
          }
        }
      );
    },

    //打开编辑窗口
    OpenProductBrandWindow(item) {
      this.ProductBrandOpt = item;
      this.ProductBrandWindow = true;
    },

    //获取属性组
    GetProductAttributeGroup() {
      axios.apiMethod(
        "/defaultData/DefaultData/GetProductAttributeGroup",
        "get",
        {},
        (result) => {
          this.AttributeGroup = result.Data;
        }
      );
    },

    OpenDefaultProductAttributeGroup(item) {
      this.AttributeGroupModel = item;
      this.AttributeGroupWindow = true;
    },
    InsterProductAttributeGroup() {
      this.AttributeGroupWindow = false;

      axios.apiMethod(
        "/defaultData/DefaultData/InsterProductAttributeGroup",
        "post",
        this.AttributeGroupModel,
        (result) => {
          this.GetProductAttributeGroup();
        }
      );
    },
    //打开属性编辑页面
    OpenAttributeGroupWindow(item) {
      this.AttributeGroupModel = item;
      this.AttributeGroupWindow = true;
    },
    //点击属性组
    GetAttributesByGroupId(item) {
      this.AttributeGroupModel = item;

      axios.apiMethod(
        "/defaultData/DefaultData/GetProductAttributeByGroupId",
        "get",
        { groupId: item.Id },
        (result) => {
          this.AttributeGroupItemModel.GroupId = this.AttributeGroupModel.Id;
          this.AttributeGroupItemModel.Sort = 0;
          this.AttributesGroupId = this.AttributeGroupModel.Id;
          this.AttributeGroupItemList = result.Data;
          this.AttributeItemWindow = true;
        }
      );
    },
    //插入属性
    InsertAttributeGroupItem() {
      axios.apiMethod(
        "/defaultData/DefaultData/InsertProductAttribute",
        "post",
        this.AttributeGroupItemModel,
        (result) => {
          this.GetAttributesByGroupId(this.AttributeGroupModel);
          this.AttributeGroupItemModel = {};
        }
      );
    },

    //删除属性
    DeleteProductAttribute(item) {
      axios.apiMethod(
        "/defaultData/DefaultData/DeleteProductAttribute",
        "get",
        { id: item.Id },
        (result) => {
          this.GetAttributesByGroupId(this.AttributeGroupModel);
        }
      );
    },
    //删除属性组
    DeleteAttributeGroupById(item) {
      axios.apiMethod(
        "/defaultData/DefaultData/DeleteProductAttributeGroup",
        "get",
        { groupId: item.Id },
        (result) => {
          this.GetProductAttributeGroup();
        }
      );
    },
  },
};
</script>
  
  <style scoped></style>
  